<template >
  <v-app id="softsim" class="pa-0 ma-0">
    <v-card class="pa-0 ma-0">
      <!-- Navigation Bar -->
      <v-app-bar
        fixed
        class="custom-light-accent"
        :elevation="BackToTopBtn?4:0"
        >
        <!-- Logo -->
        <v-app-bar-title class="custom-accent--text d-flex" v-if="company">

          <!-- Logo For Tablet And display sizes that show the navigation list and too small to have the big logo displayed as well -->
          <v-img v-if="$vuetify.breakpoint.mdAndDown && $vuetify.breakpoint.smAndUp" key="small-logo" :alt="company.alt" class="logo-small pa-0 ma-0" @click="$navigateToPath('/')" :src="storage_url+default_path+company.small_logo">
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular
                  indeterminate
                  color="custom-secondary"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <!-- Logo For Tablet And display sizes that show the navigation list and too small to have the big logo displayed as well -->
          
          <!-- Logo For Laptop And display sizes that show the navigation list and big enough to have the big logo displayed as well -->
          <v-img v-else class="logo pa-0 ma-0" key="big-logo" @click="$navigateToPath('/')" :alt="company.alt" :src="storage_url+default_path+company.logo">
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular
                  indeterminate
                  color="custom-secondary"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <!-- Logo For Laptop And display sizes that show the navigation list and big enough to have the big logo displayed as well -->

        </v-app-bar-title>

        <!-- Logo -->

        <v-spacer></v-spacer>
        
        <!-- Search Box/Navigation List With Vertical Slide Replace Animation -->
        <transition name="slide-Y" mode="out-in">
          <div class="d-none d-md-block py-auto my-auto search" style="width:min-content" key="slide-1" v-if="Search.SearchBox">
            <v-text-field
              v-model="Search.SearchTerm"
              :label="$t('search')"
              :placeholder="$t('search')"
              class="search-box"
              color="custom-accent"
              rounded
              clearable
              outlined
              dense
              @click:clear="Search.SearchTerm='';search(Search.SearchTerm)"
              @keyup="search(Search.SearchTerm)"
              append-icon="mdi-magnify"
              hide-details
              @focus="Search.SearchFocus=true"
              @blur="Search.SearchFocus=false"
              return-object
            >
            </v-text-field>

            <!-- Search Result -->
            <v-list three-line class="search-suggestions rounded-b-xl" v-if="Search.SearchFocus && Search.SearchTerm && Search.SearchResult.length>0" outlined>
              <v-row class="text-center pa-0 ma-0" v-if="Search.SearchLoading"><v-progress-circular indeterminate class="ma-auto"></v-progress-circular></v-row>
              <template v-for="(item,i) in Search.SearchResult" v-else>

                <!-- MouseDown Event Instead Of Click Because The Parent Has Blur Event Which Executes Before the Click Event -->
                <v-list-item :key="item.id" @mousedown="$navigateToPath('/blog/news-events/'+Search.SearchTerm+'/'+item.id)" link>
                  <v-list-item-avatar>
                    <v-img v-if="item.image" :src="storage_url+path+item.image" :alt="item.alt">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="custom-secondary"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                    <v-img v-else :src="storage_url+default_path+'noimage.png'" :alt="$t('noimagealt')">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="custom-secondary"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="v-html-line-clamp-1 py-1">{{$i18n.locale=='en'?item.titleEN:item.titleFR}}</v-list-item-title>
                    <v-list-item-subtitle><span class="v-html-line-clamp-2 py-2" v-html="$i18n.locale=='en'?item.descriptionEN:item.descriptionFR"></span></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <!-- MouseDown Event Instead Of Click Because The Parent Has Blur Event Which Executes Before the Click Event -->

                <v-divider :key="'divider-'+item.id" v-if="Search.SearchResult.length>0 && i+1<Search.SearchResult.length"></v-divider>
              </template>
            </v-list>
            <!-- Search Result -->

            <!-- Search Result Not Found -->
            <v-list three-line class="search-suggestions rounded-b-xl" v-if="Search.SearchResult.length==0 && Search.SearchTerm && !Search.SearchLoading" outlined>
             <v-row class="text-center pa-0 ma-0" ><span class="ma-auto text-h5 text--secondary">{{$t("noresult")}} "{{Search.SearchTerm}}"</span></v-row>
            </v-list>
            <!-- Search Result Not Found -->

          </div>
          <div class="d-none d-md-flex overflow-auto" key="slide-2" v-else>
            <v-menu v-for="item in NavigationListItems"
              :key="item.id"
              :prepend-icon="item.icon"
              transition="slide-y-transition" content-class="elevation-1"
              rounded="b-lg"
              offset-y
              md-open-on-hover
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" text :title="item.title"
                :disabled="item.disabled" class="ml-1">
                  <span> {{item.title}} </span>
                  <v-icon right v-if="attrs['aria-expanded']=='true'">mdi-chevron-up</v-icon>
                  <v-icon right v-else>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list text>
                <v-list-item-group color="custom-accent">
                  <v-list-item
                    v-for="subitem in item.items"
                    :key="subitem.title"
                    :to="subitem.route"
                    :disabled="subitem.disabled"
                    link
                  >
                    <v-list-item-icon>
                      <v-icon :disabled="subitem.disabled">{{ subitem.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ subitem.title }}</v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </div>
        </transition>
        <!-- Search Box/Navigation List With Vertical Slide Replace Animation -->
        
        <!-- Vertical Divider For Search Box/Navigation List and Navigation Icons/Navigation Drawer Activator Icon -->
        <v-divider vertical class="mx-2"></v-divider>
        <!-- Vertical Divider For Search Box/Navigation List and Navigation Icons/Navigation Drawer Activator Icon -->

        <div class="d-none d-md-flex">
          
          <!-- Search Box Activator With Fade and Rotate Appearing Animation -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">                
              <v-btn icon @click="Search.SearchBox=!Search.SearchBox" :title="$t('search')" v-bind="attrs" v-on="on">
              <transition name="fade-rotate" mode="out-in">
                <v-icon v-if="Search.SearchBox" key="icon-fade-1">mdi-close</v-icon>
                <v-icon v-else key="icon-fade-2">mdi-magnify</v-icon>
              </transition>
              </v-btn>
            </template>
            <span v-if="Search.SearchBox">{{$t('close')}}</span>
            <span v-else>{{$t('search')}}</span>
          </v-tooltip>
          <!-- Search Box Activator With Fade and Rotate Appearing Animation -->

          <!-- Navigation Icons For Desktop and Medium sized Tablets Users (For Loop) -->
          <v-tooltip v-for="item in NavigationItems"
            :key="item.title" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" :title="item.title" @click="$navigateToPath(item.route)">
                <v-icon>{{item.icon}}</v-icon>
              </v-btn>
            </template>
            <span>{{item.title}}</span>
          </v-tooltip>
          <!-- Navigation Icons For Desktop and Medium sized Tablets Users (For Loop) -->

          <!-- Language Selector For Desktop and Medium sized Tablets Users -->
          <v-menu transition="slide-y-transition" content-class="elevation-1"
            rounded="b-lg"
            offset-y
          >
            <template v-slot:activator="{ on: menu, attrs }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                    <v-btn v-bind="attrs" v-on="{ ...tooltip, ...menu }" :title="$t('language')+':'+$t($SupportedLanguages.filter(i=>i.abbr==$i18n.locale)[0].name)" icon>
                      <v-icon>mdi-web</v-icon>
                    </v-btn>
                </template>
                <span>{{$t("language")}}:{{$t($SupportedLanguages.filter(i=>i.abbr==$i18n.locale)[0].name)}}</span>
              </v-tooltip>
            </template>
            <v-list text>
              <v-list-item-group color="custom-accent"
                v-model="CurrentLanguage"
                @change="changeLanguage"
                mandatory
              >
                <v-list-item
                  v-for="item in $SupportedLanguages"
                  :value="item.abbr"
                  :key="item.abbr"
                >
                  <v-list-item-title>{{ $t(item.name) }}</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
          <!-- Language Selector For Desktop and Medium sized Tablets Users -->
          <logged-in-menu v-if="isLoggedIn"></logged-in-menu>
        </div>

        <!-- Navigation Drawer Activator Icon For Mobile Users and Small sized Tablets -->
        <div class="d-flex d-md-none">
          <v-app-bar-nav-icon @click="Drawer=!Drawer" :title="$t('expanddrawer')"></v-app-bar-nav-icon>
          <logged-in-menu v-if="isLoggedIn"></logged-in-menu>
        </div>
        <!-- Navigation Drawer Activator Icon For Mobile Users and Small sized Tablets -->

      </v-app-bar>
      <!-- Navigation Bar -->


      <!-- Navigation Drawer For Mobile Users and Small sized Tablets-->
      <v-navigation-drawer
        v-model="Drawer"
        fixed
        temporary
      >

        <!-- Logo -->
        <v-list-item v-if="company">
          <v-list-item-content>
            <v-img class="pa-0 ma-0 logo" @click="$navigateToPath('/')" :src="storage_url+default_path+company.logo" :alt="company.alt">
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="custom-secondary"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-list-item-content>
        </v-list-item>
        <!-- Logo -->

        <v-divider></v-divider>

        <v-list dense>

          <!-- Search Box Always Appearing for Mobile Users and Small sized Tablets -->
          <v-list-item class="d-block d-md-none py-auto my-auto search" style="width:100%">
            <v-list-item-content>
              <v-text-field
                v-model="Search.SearchTerm"
                :label="$t('search')"
                :placeholder="$t('search')"
                class="pa-0 ma-0"
                rounded
                clearable
                outlined
                dense
                @click:clear="Search.SearchTerm='';search(Search.SearchTerm)"
                @keyup="search(Search.SearchTerm)"
                append-icon="mdi-magnify"
                hide-details
                @focus="Search.SearchFocus=true"
                @blur="Search.SearchFocus=false"
                return-object
              >
              </v-text-field>

            <!-- Search Result -->
            <v-list three-line class="search-suggestions-mobile rounded-b-xl" v-if="Search.SearchFocus && Search.SearchTerm && Search.SearchResult.length>0" outlined>
              <v-row class="text-center pa-0 ma-0" v-if="Search.SearchLoading"><v-progress-circular indeterminate class="ma-auto"></v-progress-circular></v-row>
              <template v-for="(item,i) in Search.SearchResult" v-else>

                <!-- MouseDown Event Instead Of Click Because The Parent Has Blur Event Which Executes Before the Click Event -->
                <v-list-item :key="item.id" @mousedown="$navigateToPath('/blog/news-events/'+Search.SearchTerm+'/'+item.id)" link>
                  <v-list-item-avatar>
                    <v-img v-if="item.image" :src="storage_url+path+item.image" :alt="item.alt">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="custom-secondary"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                    <v-img v-else :src="storage_url+default_path+'noimage.png'" :alt="$t('noimagealt')">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="custom-secondary"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="v-html-line-clamp-1 py-1">{{$i18n.locale=='en'?item.titleEN:item.titleFR}}</v-list-item-title>
                    <v-list-item-subtitle><span class="v-html-line-clamp-2 py-2" v-html="$i18n.locale=='en'?item.descriptionEN:item.descriptionFR"></span></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <!-- MouseDown Event Instead Of Click Because The Parent Has Blur Event Which Executes Before the Click Event -->

                <v-divider :key="'divider-'+item.id" v-if="Search.SearchResult.length>0 && i+1<Search.SearchResult.length"></v-divider>
              </template>
            </v-list>
            <!-- Search Result -->

            <!-- Search Result Not Found -->
            <v-list three-line class="search-suggestions-mobile rounded-b-xl" v-if="Search.SearchResult.length==0 && Search.SearchTerm && !Search.SearchLoading" outlined>
             <v-row class="text-center pa-0 ma-0" ><span class="ma-auto text-h7 text--secondary">{{$t("noresult")}}<span v-if="Search.SearchTerm">{{$t("resultfor")}}</span></span></v-row>
            </v-list>
            <!-- Search Result Not Found -->

            </v-list-item-content>
          </v-list-item>
          <!-- Search Box Always Appearing for Mobile Users and Small sized Tablets -->

          <!-- Navigation Icons and Description For Mobile Users and Small sized Tablets (For Loop) -->
          <v-list-item
            v-for="item in NavigationItems"
            color="custom-accent" 
            :key="item.title"
            :to="item.route"
            link
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- Navigation Icons and Description For Mobile Users and Small sized Tablets (For Loop) -->

          <!-- Navigation List and Description For Mobile Users and Small sized Tablets (For Loop) -->
          <v-list-group
            v-for="item in NavigationListItems"
            color="custom-accent"
            :key="item.id"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon :disabled="item.disabled" v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title :class="item.disabled?'text--disabled':''" v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="subitem in item.items"
              :key="subitem.title"
              :to="subitem.route"
              :disabled="subitem.disabled"
              link
            >
              <v-list-item-icon>
                <v-icon :disabled="subitem.disabled">{{subitem.icon}}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ subitem.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <!-- Navigation List and Description For Mobile Users and Small sized Tablets (For Loop) -->

        </v-list>

        <!-- Language Selector For Mobile Users and Small sized Tablets -->
        <div class="language-mobile-menu">
          <v-menu transition="slide-y-transition" content-class="elevation-1"
            rounded="t-lg"
            top
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" :title="$t('language')+':'+$t($SupportedLanguages.filter(i=>i.abbr==$i18n.locale)[0].name)" icon>
                <v-icon>mdi-web</v-icon>
              </v-btn>
            </template>
            <v-list text>
              <v-list-item-group
                v-model="CurrentLanguage"
                color="custom-accent"
                @change="changeLanguage"
                mandatory
              >
                <v-list-item
                  v-for="item in $SupportedLanguages"
                  :value="item.abbr"
                  :key="item.abbr"
                >
                  <v-list-item-title>{{ $t(item.name) }}</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </div>
        <!-- Language Selector For Mobile Users and Small sized Tablets -->

      </v-navigation-drawer>
      <!-- Navigation Drawer For Mobile Users and Small sized Tablets -->

      <!-- Main Section -->
      <v-sheet 
        id="main"
        class="custom-off-white"
      >
        <v-container fluid class="main-layout-container px-0 pb-12 pb-md-0" ref="main" id="main-section">
          <v-breadcrumbs data-aos="slide-right" dark class="bread-crumbs pa-0 ma-0 px-2" :items="breadcrumbs" large> 
            <template v-slot:divider>
              <v-icon>mdi-chevron-right</v-icon>
            </template>
            <template v-slot:item="{ item }">
              <v-breadcrumbs-item
                @click="$navigateToPath(item.href)"
                :style="!item.disabled?'cursor:pointer':''"
                class="white--text"
                :disabled="item.disabled"
              >
                {{ $t(item.text) }}
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>
          <router-view data-aos="fade-down" data-aos-duration="400" class="main" id="main-router"></router-view>

          <!-- footer Section -->
          <v-row class="" data-aos="fade-down" data-aos-anchor="#main-router">
            <footer-component class="mb-2"></footer-component>
          </v-row>
          <!-- footer Section -->

        </v-container>
      </v-sheet>
      <!-- Main Section -->


      <!-- Back To Top Button With Fade and Rotate Appearing Animation -->
      <transition name="fade-rotate" mode="out-in">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
              <v-btn v-show="BackToTopBtn" fixed bottom right style="z-index:4;" class="mb-12 mb-md-auto" v-bind="attrs" v-on="on" @click="toTop" icon :title="$t('backtotop')" v-scroll="onScroll">
                <v-icon large>mdi-arrow-up-circle</v-icon>
              </v-btn>
          </template>
          <span>{{ $t("backtotop") }}</span>
        </v-tooltip>
      </transition>
      <!-- Back To Top Button With Fade and Rotate Appearing Animation -->

      <!-- Footer Section Containing Navigation Icons For Mobile Users and Small sized Tablets -->
      <v-footer fixed padless class="d-block d-md-none">
        <v-bottom-navigation
            absolute
            :background-color="BottomNavigationColor"
            dark
            grow
            shift
          >
            <v-btn v-for="item in NavigationItems" :title="item.title" :to="item.route" :key="item.title" text>
              <span>{{item.title}}</span>
              <v-icon>{{item.icon}}</v-icon>
            </v-btn>
        </v-bottom-navigation>
      </v-footer>
      <!-- Footer Section Containing Navigation Icons For Mobile Users and Small sized Tablets -->
    </v-card>

  </v-app>
</template>
<script>
import footer from '../components/Layout/Footer.vue';
import loggedinmenu from '../components/Admin/LoggedInMenu.vue';
import { mapState,mapActions,mapGetters} from "vuex";
import LoggedInMenu from '../components/Admin/LoggedInMenu.vue';
export default {
  name: "MainPage",

  data() {
    return {
      BackToTopBtn:false,
      Drawer:false,
      CurrentLanguage:localStorage.getItem('language'),
      Search:{
        SearchTerm:'',
        SearchBox:false,
        SearchLoading:true,
        SearchFocus:false,
        SearchResult:[],
      },
      breadcrumbs: this.$route.meta.breadcrumb,

      storage_url: process.env.VUE_APP_STORAGEURL,
      path: "blogs/newsevents/images/",
      default_path: "default_images/",
    };
  },
  components: {
    "footer-component":footer,
    "logged-in-menu":loggedinmenu,
    LoggedInMenu,
  },
  async created() {
    await this.retrieveBlogs();
    await this.retrieveRoles();
    await this.retrieveUsers();
    await this.retrieveGallery();
    await this.retrieveClients();
    await this.retrievePartners();
    await this.retrieveProducts();
    await this.retrieveServices();
    await this.retrieveCompanies();
    await this.retrieveStatistics();
    await this.retrievePermissions();
    await this.retrieveCompanyValues();
    await this.retrieveOpportunities();
    await this.retrieveCompanySocials();
    await this.retrieveProductServices();
    await this.retrieveCompanyAddresses();
    await this.retrieveProductAttachments();
  },
  mounted() {
    this.$root.$on('callNotify', this.callNotify);
    this.$root.$on('toTop', this.toTop);
  },
  computed: {
    BottomNavigationColor: function () {
      switch (this.$route.name) {
        case 'home': return 'custom-accent'
        case 'contact': return 'teal darken-3'
        case 'about': return 'blue-grey darken-1'
        case 'products': return 'light-blue darken-4'
        case 'clients': return 'secondary'
        case 'newsevents': return 'custom-accent darken-1'
        case 'gallery': return 'orange darken-2'
        case 'jobseeker': return 'brown darken-2'
        default: return 'custom-accent'
      }
    },
    NavigationItems: function () {
      return [
        { title: this.$t('home'), icon: 'mdi-home', route: '/' },
        { title: this.$t('contact'), icon: 'mdi-headset', route: '/contact' },
        { title: this.$t('about'), icon: 'mdi-information', route: '/about' },
      ];
    },
    NavigationListItems: function () {
      return [
        { 
          id: 1,
          title: this.$t('solutions'),
          icon: 'mdi-lightbulb-on',
          disabled:false,
          items: [
            { title: this.$t('innovations'), icon: 'mdi-head-lightbulb', route: '/solutions/innovations', disabled:false },
            { title: this.$t('services'), icon: 'mdi-cogs', route: '/solutions/services', disabled:false },
            { title: this.$t('products'), icon: 'mdi-basket', route: '/solutions/products', disabled:false },
          ],
        },
        {
          id: 2,
          title: this.$t('clients')+' & '+this.$t('partners'),
          icon: 'mdi-account-group',
          disabled:false,
          items: [
            { title: this.$t('clients'), icon: 'mdi-account-multiple', route: '/clients-partners/clients', disabled:false },
            { title: this.$t('partners'), icon: 'mdi-handshake', route: '/clients-partners/partners', disabled:false },
          ],
        },
        {
          id: 3,
          title: this.$t('blog'),
          icon: 'mdi-post',
          disabled:false,
          items: [
            { title: this.$t('news')+' & '+this.$t('events'), icon: 'mdi-newspaper-variant-multiple', route: '/blog/news-events', disabled:false },
            { title: this.$t('gallery'), icon: 'mdi-image-multiple', route: '/blog/gallery', disabled:false },
          ],
        },
        {
          id: 4,
          title: this.$t('recruitment'),
          icon: 'mdi-briefcase-search',
          disabled:false,
          items: [
            { title: this.$t('jobseeker'), icon: 'mdi-briefcase', route: '/recruitment/job-seeker', disabled:false },
            { title: this.$t('employer'), icon: 'mdi-briefcase-account', route: '/recruitment/employer', disabled:false },
          ],
        },
      ];
    },
    ...mapGetters('company',{company:'getCompany'}),
    ...mapGetters('user',{isLoggedIn:'isLoggedIn'}),
    ...mapGetters('blog', {blogs:'getBlogs'}),
    ...mapState("blog", {loading: "loading"}),
  },
  watch: {
    $route() {
      this.toTop();
      this.breadcrumbs = this.$route.meta.breadcrumb;
    },
  },
  methods: {
    search(item){
      this.Search.SearchLoading=true
      this.Search.SearchResult = '';
      if(item)
        this.Search.SearchResult = this.blogs.filter(blog => {
          if(this.$i18n.locale=='en'){
          return blog.titleEN?.toLowerCase().includes(item.toLowerCase()) || blog.descriptionEN?.toLowerCase().includes(item.toLowerCase());
          }
          else{
          return blog.titleFR?.toLowerCase().includes(item.toLowerCase()) || blog.descriptionFR?.toLowerCase().includes(item.toLowerCase()); 
          }
        });
      this.Search.SearchLoading=false;
      
    },
    changeLanguage(item){
      if(!item)
        item=localStorage.getItem('language');
      localStorage.setItem('language',item);
      this.$i18n.locale=item;
    },
    onScroll(e){
      if (typeof window === 'undefined') return
      let top = window.pageYOffset || e.target.scrollTop || 0
      this.BackToTopBtn = top > 20
    },
    async toTop() {
      this.$vuetify.goTo(0)
    },
    callNotify(item) {
      let notifSound;
      switch (item.type) {
        case 'success':
          notifSound = new Audio(require('../assets/audios/success.wav'));
          break;
      
        case 'warn':
          notifSound = new Audio(require('../assets/audios/error.wav'));
          break;
      
        default:
          break;
      }
      try {
        notifSound.play();
      } catch (err) {
        console.log('Failed to play...' + err);
      }

      this.$notify({
          // (optional)
          // Name of the notification holder
          group: 'app',
      
          // (optional)
          // Class that will be assigned to the notification
          type: item.type,
      
          // (optional)
          // Title (will be wrapped in div.notification-title)
          title: item.title,
      
          // Content (will be wrapped in div.notification-content)
          text: item.content,
      
          // (optional)
          // Overrides default/provided duration
          duration: 5000,
      
          // (optional)
          // Overrides default/provided animation speed
          speed: 1000
        })
    },

    ...mapActions("user", ["retrieveUsers"]),
    ...mapActions("role", ["retrieveRoles"]),
    ...mapActions("blog", ["retrieveBlogs"]),
    ...mapActions("client", ["retrieveClients"]),
    ...mapActions("gallery", ["retrieveGallery"]),
    ...mapActions("service", ["retrieveServices"]),
    ...mapActions("product", ["retrieveProducts"]),
    ...mapActions("partner", ["retrievePartners"]),
    ...mapActions("company", ["retrieveCompanies"]),
    ...mapActions("statistic", ["retrieveStatistics"]),
    ...mapActions("permission", ["retrievePermissions"]),
    ...mapActions("opportunity", ["retrieveOpportunities"]),
    ...mapActions("company_value", ["retrieveCompanyValues"]),
    ...mapActions("company_social", ["retrieveCompanySocials"]),
    ...mapActions("product_service", ["retrieveProductServices"]),
    ...mapActions("company_address", ["retrieveCompanyAddresses"]),
    ...mapActions("product_attachment", ["retrieveProductAttachments"]),
  },
};
</script>
 
<style>
@import '../assets/styles/main.css';
</style>