<template> 
    <v-menu id="loggedinmenu" transition="slide-y-transition" content-class="elevation-1"
        bottom
        v-if="user && isLoggedIn"
        min-width="250"
        rounded
        :close-on-content-click="false"
        offset-y>
        <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                    <v-btn :title="user.fullname" icon v-bind="attrs" v-on="{ ...tooltip, ...menu }" outlined dark class="ma-0 pa-1" color="custom-accent">
                        <v-avatar class="custom-accent" size="40" dark>
                            <span class="text-uppercase custom-white--text">{{ user.firstname.charAt(0)}}{{ user.lastname.charAt(0) }}</span>
                        </v-avatar>
                    </v-btn>
                </template>
                <span>{{user.fullname}}</span>
            </v-tooltip>
        </template>
        <v-card>
            <v-list text>
                <v-list-item>
                    <v-list-item-avatar>
                        <v-avatar
                        class="custom-accent--text"
                        outlined
                        size="50">
                            <span class="headline pa-0 ma-0 text-uppercase">{{ user.firstname.charAt(0)}}{{ user.lastname.charAt(0) }}</span>
                        </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>{{ user.fullname }}</v-list-item-title>
                        <v-list-item-subtitle>@{{ user.username }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list>

                <v-list-item :to="{ path: '/profile' }">
                    <v-list-item-icon>
                        <v-icon>mdi-account-circle</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ $t("profile") }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item :to="{ path: '/inbox' }">
                    <v-list-item-icon>
                        <v-icon>mdi-inbox</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ $t("inbox") }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item :to="{ path: '/roles-permissions' }" v-if="user.permission.map(i=>i.name).includes('roles.read')">
                    <v-list-item-icon>
                        <v-icon>mdi-key-chain</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ $t("rolespermissions") }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item @click="logout()">
                    <v-list-item-icon>
                        <v-icon>mdi-logout</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ $t("logout") }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

            </v-list>
        </v-card>
    </v-menu>
</template>
<script>

import { mapActions,mapGetters } from "vuex";
export default({
    name: 'LoggedInMenu',
    data() {
        return {
        }
    },
    async created(){
    },
    computed: {
        ...mapGetters('user',{user:'getUser',isLoggedIn:'isLoggedIn'}),
    },
    watch:{},
    methods: {
        logout() {
            this.destroyToken().then(() => {
                this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t('loggedout'), title:this.$t('success'), type:'success'});
                this.$router.push({ name: "login" });
            });
        },

        ...mapActions('user',["destroyToken"]),
    }
})
</script>
<style scoped>
</style>