<template>
  <v-card
    flat
    tile
    width="100%"
    class="custom-light-accent text-center pa-0 ma-0"
  >
    <div v-if="company_loading || company_social_loading">
       <v-skeleton-loader type="image" height="125"></v-skeleton-loader>
    </div>
    <div v-else class="pa-0 ma-0">
      <v-card-text>
        <span v-for="social in company_socials" :key="social.id" class="mr-1">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :href="social.url"
                :title="social.name"
                target="_blank"
                v-bind="attrs"
                v-on="on"
                icon
              >
                <v-icon large>
                  {{ social.icon }}
                </v-icon>
              </v-btn>
            </template>
            <span>{{ social.name }}</span>
          </v-tooltip>
        </span>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text>
        <span class="custom-secondary--text">{{ new Date().getFullYear() }} — <strong>{{ company.title }}</strong></span>
      </v-card-text>
    </div>
  </v-card>
</template>
<script>
import { mapState,mapGetters} from "vuex";
export default {
  name: "Footer",
  data() {
    return {
    };
  },
  components:{
  },
  async created(){
  },
  computed:{
    ...mapGetters('company', {
      company:'getCompany',
    }),
    ...mapState("company", {
      company_loading: "loading"
    }),
    ...mapGetters('company_social', {
      company_socials:'getCurrentCompanySocials'
    }),
    ...mapState("company_social", {
      company_social_loading: "loading"
    }),
  },
  watch:{
  },
  methods:{

  },
};
</script>
 
